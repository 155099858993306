<template>
  <b-modal v-model="modals.reviews" :title=" $t('dashboard.menu.reviews') " centered content-class="card card-ecart"
    header-class="card-header" @hidden="changeModal({reviews:false})" size="lg" hide-header-close>
    <ProductReviews :productId="productInformation.id" />
    <template v-slot:modal-footer>
      <p class="text-right">
        <b-button variant="none" size="sm" class=" mr-2" @click=" changeModal({reviews:false}) "
          v-text=" $t('general.button.close') ">
        </b-button>
      </p>
    </template>
  </b-modal>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex';
  import ProductReviews from "@/components/dashboard/product/product-reviews";
  export default {
    components: {
      ProductReviews,
    },
    data() {
      return ({});
    },
    computed: {
      ...mapState('EcartProduct', ['modals', 'productInformation'])
    },
    methods: {
      ...mapMutations('EcartProduct', ['changeModal']),
    },
  }
</script>